<template>
    <div class="row tgju-widgets-row">
        <slot v-if="widgetLoad">
            <div class="text-center p-2 w100-w">
                <div class="widget-loading"></div>
            </div>
        </slot>
        <slot v-else>

            <div class="market-profile-inner">
                <div class="profile-inner-filter-row">
                    <div class="filter-row-name">
                        <i class="fa fa-clock-o" aria-hidden="true"></i> {{ market_history.info.time }}
                    </div>
                    <div class="filter-row-btns">
                        <i class="uil uil uil-search" @click="showSearch = !showSearch, showFilter = false"></i>
                        <i class="uil uil uil-filter" @click="showFilter = !showFilter, showSearch = false"></i>
                    </div>
                </div>
                <div v-if="showFilter || showSearch" class="market-profile-filter-box">

                    <data-picker v-if="showFilter" class="mobile-app-input" label="از تاریخ" id="history-from"
                        v-model="fromDate" @change="chartDatesChanged()"></data-picker>
                    <data-picker v-if="showFilter" class="mobile-app-input" label="تا تاریخ" id="history-to"
                        v-model="toDate" @change="chartDatesChanged()"></data-picker>
                    <!-- <slot v-if="cryptoembed == null">
                        <button v-if="showFilter" type="submit" class="w-100 tgju-btn tgju-btn-lg tgju-btn-primary" onclick="tv_select('archive');" style="padding: 9px 15px 13px;margin-bottom: 10px;"><i class="uil uil-th"></i> انتخاب شاخص</button>
                    </slot> -->
                    <v-text-field v-if="showSearch" v-model="searchVal" @input="search()" id="datatables-search-box"
                        class="mobile-app-input" style="margin-bottom: 7px;" label="جستجو ..."></v-text-field>
                </div>

                <ul class="m-pointer">
                    <li class="li-head">
                        <div class="profile-inner-table-row">
                            <div class="profile-inner-table-name">شمسی</div>
                            <div class="profile-inner-table-value">میلادی</div>
                            <div class="profile-inner-table-value flex-grow-1 text-align-left">پایانی</div>
                            <div class="profile-inner-table-actions actions-min-w"></div>
                        </div>
                    </li>

                    <li v-for="(item, index) in market_history.summary_table" :key="index"
                        :class="activeLi == index ? 'active-current' : ''">
                        <div class="profile-inner-table-row" @click="setActive(index)">
                            <div class="profile-inner-table-name">{{ item.jalali_date }}</div>
                            <div class="profile-inner-table-value">{{ item.date }}</div>
                            <div class="profile-inner-table-value flex-grow-1 text-align-left">{{ item.price }}</div>
                            <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                        </div>
                        <div class="profile-inner-table-box">
                            <div class="profile-inner-table-item">
                                <div class="profile-inner-table-row">
                                    <div class="profile-inner-table-name">بازگشایی</div>
                                    <div class="profile-inner-table-value">{{ item.open }}</div>
                                </div>
                            </div>
                            <div class="profile-inner-table-item">
                                <div class="profile-inner-table-row">
                                    <div class="profile-inner-table-name">کمترین</div>
                                    <div class="profile-inner-table-value">{{ item.min }}</div>
                                </div>
                            </div>
                            <div class="profile-inner-table-item">
                                <div class="profile-inner-table-row">
                                    <div class="profile-inner-table-name">بیشترین</div>
                                    <div class="profile-inner-table-value">{{ item.max }}</div>
                                </div>
                            </div>
                            <div class="profile-inner-table-item">
                                <div class="profile-inner-table-row">
                                    <div class="profile-inner-table-name">پایانی</div>
                                    <div class="profile-inner-table-value">{{ item.price }}</div>
                                </div>
                            </div>
                            <div class="profile-inner-table-item">
                                <div class="profile-inner-table-row">
                                    <div class="profile-inner-table-name">میزان تغییر</div>
                                    <div class="profile-inner-table-value" :class="`${item.dt}`">{{ item.d }}</div>
                                </div>
                            </div>
                            <div class="profile-inner-table-item">
                                <div class="profile-inner-table-row">
                                    <div class="profile-inner-table-name">درصد تغییر</div>
                                    <div class="profile-inner-table-value" v-if="item.dp != '-'" :class="`${item.dt}`"
                                        v-html="item.dp + '%'"></div>
                                    <div class="profile-inner-table-value" v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>

            <div  class="p-3 d-flex justify-content-center pagination-box-items">
                <Paginate :page-count="pagination.last_page" :click-handler="getProfileHistory" :prev-text="'قبل'"
                    :next-text="'بعد'" :container-class="'pagination'"></Paginate>
            </div>

        </slot>
    </div>
</template>
    
<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import data_picker from 'vue-persian-datetime-picker';
import Paginate from "vuejs-paginate";

import $ from "jquery";
export default {
    name: "MarketHistoryComponent",
    components: {
        'data-picker': data_picker,
        Paginate,
    },
    data() {
        return {
            widgetLoad: true,
            initDatatable: false,
            market_history: [],
            cryptoembed: null,
            fromDate: null,
            toDate: null,
            datatable: null,
            showSearch: false,
            showFilter: false,
            activeLi: null,
            searchVal: '',
            page: 1,
            pagination: {
                length: undefined,
                current_page: undefined,
                from: undefined,
                last_page: undefined,
                next_page_url: undefined,
                per_page: undefined,
                prev_page_url: undefined,
                to: undefined,
                total: undefined,
            },

        }
    },
    updated() {
        if (!this.initDatatable) {
            this.initDatatables();
            this.initDatatable = true;
        }
    },
    mounted() {
        this.getProfileHistory(1);
    },
    methods: {

        search() {
            var value = this.searchVal.toLowerCase();
            $(".market-profile-inner li").each(function () {
                var x = $(this).text().toLowerCase().indexOf(value) > -1;
                $(this).toggle(x)
            });
        },
        setActive(index) {
            if (index == this.activeLi) {
                this.activeLi = null;
            } else {
                this.activeLi = index;
            }

        },
        getProfileHistory(page) {
            console.log(this.$route.params.name);
            console.log(this.fromDate);
            console.log(this.toDate);
            this.$helpers
                .makeRequest("GET", `/market/profile-history/${this.$route.params.name}`, {
                    'from_date': this.fromDate,
                    'to_date': this.toDate,
                    'page':page
                })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_history = api_response.data.response.detail.response;
                        this.pagination.last_page=api_response.data.response.last_page
                        this.$parent.title = this.market_history.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                        console.log(this.market_history)
                        this.initDatatables();
                    }
                });
        },
        initDatatables() {
            console.log('initDatatables');
            this.datatable = $('#datatables-container-1').DataTable({
                language: {
                    "sProcessing": "درحال پردازش...",
                    "sLengthMenu": "نمایش محتویات : _MENU_",
                    "sZeroRecords": "موردی یافت نشد",
                    "sInfo": "نمایش _START_ تا _END_ از مجموع _TOTAL_ مورد",
                    "sInfoEmpty": "خالی",
                    "sInfoFiltered": "(فیلتر شده از مجموع _MAX_ مورد)",
                    "sInfoPostFix": "",
                    "sSearch": "فیلتر : ",
                    "sUrl": "",
                    "oPaginate": {
                        "sFirst": "ابتدا",
                        "sPrevious": "قبلی",
                        "sNext": "بعدی",
                        "sLast": "انتها"
                    }
                },
                aaSorting: [],
                ordering: true,
                paging: true,
                info: true,
                columnDefs: [{
                    "targets": 'no-sort',
                    "orderable": false,
                }],
                pageLength: 30,
                // TODO: "<'row'<'col-md-9'l T C><'col-md-3'f>r>t<'row'<'col-md-12'p i>>R",
                sDom: "<''<'col-md-9'l T C><'col-md-3'f>r>t<''<'col-md-12'p i>>R",
                direction: 'rtl',
                processing: true,
                serverSide: true,
                ajax: {
                    "url": `https://api.tgju.org/v1/market/indicator/summary-table-data/${this.$route.params.name}?lang=fa&identification=smart&order_dir=asc`,
                    "dataType": "json",
                    "data": this.history_datatables_params
                }
            });

            var test_ajax = this.datatable;
            $('#datatables-search-box').keyup(function () {
                test_ajax.search($(this).val()).draw();
            });
        },
        chartDatesChanged() {
            console.log('chartDatesChanged');
            console.log(this.datatable);
            this.datatable.ajax.reload();
            this.page=1;
            this.getProfileHistory(1);
        },
        history_datatables_params(data) {
            // TODO: به vpd پایین دقت شود شاید داینامیک عوض بشه
            var from = $('#vpd-history-from').val();
            var to = $('#vpd-history-to').val();

            var order_col = data.order.length ? data.order[0]['column'] : null;

            if (order_col !== null) {
                switch (order_col) {
                    case 0:
                        order_col = 'open';
                        break;
                    case 1:
                        order_col = 'min';
                        break;
                    case 2:
                        order_col = 'max';
                        break;
                    case 3:
                        order_col = 'price';
                        break;
                    case 4:
                        order_col = 'd';
                        break;
                    case 5:
                        order_col = 'dp';
                        break;
                    case 6:
                        order_col = 'timestamp';
                        break;
                    case 7:
                        order_col = 'timestamp';
                        break;
                }
            }

            return $.extend({}, data, {
                search: $('.filters-wrapper input.search').val().trim(),
                order_col: order_col,
                order_dir: data.order.length ? data.order[0]['dir'] : null,
                from: from,
                to: to,
                convert_to_ad: 1,
            });
        },
    },
}
</script>